import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import {
  FdrLink,
  FdrLinkOpenMethod,
  FdrLinkType,
} from '@hotelplan/supergraph-api';
import { ACCOUNT_TABS_TABLE } from 'fdr/components/local/fdr-my-account';

export function getMenuItems(
  locale: string
): Array<{ label: string; routerProps: FdrLink }> {
  return Object.keys(ACCOUNT_TABS_TABLE).reduce((acc, pageType: EPageType) => {
    const route = getRouteByPageTypeLocale(pageType, locale);

    if (!route) return acc;

    acc.push({
      label: ACCOUNT_TABS_TABLE[pageType].label,
      routerProps: {
        url: route.builder({}),
        type: FdrLinkType.Internal,
        openMethod: FdrLinkOpenMethod.ThisTab,
      },
    });

    return acc;
  }, []);
}
