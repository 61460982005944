import { useTranslation } from 'next-i18next';
import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  FdrAccountHeaderButton,
  FdrAccountHeaderMenu,
} from 'fdr/components/candidate/fdr-my-account/fdr-account-header-button';
import { FdrSiteToolTooltip } from 'fdr/components/candidate/fdr-site-tool-tooltip';
import { useFdrAccountCustomerNameQuery } from 'fdr/schemas/query/account/fdr-account-customer-name.generated';
import { FdrAccountHeaderWrapper } from './fdr-account-header.styles';

interface IFdrAccountHeaderProps {
  isAuthenticated: boolean;
}

export function FdrAccountHeader({
  isAuthenticated,
}: IFdrAccountHeaderProps): React.ReactElement {
  const [isMenuOpened, , close, toggle] = useToggleState(false);
  const [t] = useTranslation('common');
  const { mobile } = useDeviceType();

  const { data, loading } = useFdrAccountCustomerNameQuery({
    skip: !isMenuOpened,
  });

  const { firstName, lastName } =
    data?.fdrAccount?.profile?.personalData?.accountCustomer || {};

  const wrappedButton = (
    <FdrAccountHeaderWrapper>
      <FdrAccountHeaderButton
        isAuthenticated={isAuthenticated}
        isMenuOpened={isMenuOpened}
        closeMenu={close}
        toggleMenu={toggle}
        accountHeaderMenu={
          <FdrAccountHeaderMenu
            accountData={{
              firstName,
              lastName,
            }}
            accountDataLoading={loading}
            isOpened={isMenuOpened}
            onMenuItemClick={close}
          />
        }
      />
    </FdrAccountHeaderWrapper>
  );

  return mobile ? (
    wrappedButton
  ) : (
    <FdrSiteToolTooltip text={t('myaccount.header.tooltip')}>
      {wrappedButton}
    </FdrSiteToolTooltip>
  );
}
