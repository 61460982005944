import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrSearchHistory } from 'fdr/components/local/fdr-search-history';

export const SearchHistoryWrapper = styled.div(
  sx2CssThemeFn({
    width: '375px',
    top: '101%',
    position: 'absolute',
    right: 'calc((100% - 1350px) / 2 + 1px)',
    zIndex: '10',
  })
);

export const MySearchHistory = styled(FdrSearchHistory)(
  ({ theme: { space } }) => ({
    '.historyGroup': {
      marginBottom: space[4],
    },
    '.search-history-group-title': {
      marginBottom: space[2],
    },
  })
);
