import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAccountCustomerNameQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrAccountCustomerNameQuery = {
  __typename?: 'Query';
  fdrAccount?: {
    __typename?: 'FdrAccountPages';
    profile?: {
      __typename?: 'FdrAccountProfilePage';
      personalData: {
        __typename?: 'FdrAccountPersonalDataResponse';
        accountCustomer?: {
          __typename?: 'FdrAccountCustomer';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      };
    } | null;
  } | null;
};

export const FdrAccountCustomerNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrAccountCustomerName' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personalData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountCustomer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useFdrAccountCustomerNameQuery__
 *
 * To run a query within a React component, call `useFdrAccountCustomerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrAccountCustomerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrAccountCustomerNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrAccountCustomerNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrAccountCustomerNameQuery,
    FdrAccountCustomerNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrAccountCustomerNameQuery,
    FdrAccountCustomerNameQueryVariables
  >(FdrAccountCustomerNameDocument, options);
}
export function useFdrAccountCustomerNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrAccountCustomerNameQuery,
    FdrAccountCustomerNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrAccountCustomerNameQuery,
    FdrAccountCustomerNameQueryVariables
  >(FdrAccountCustomerNameDocument, options);
}
export type FdrAccountCustomerNameQueryHookResult = ReturnType<
  typeof useFdrAccountCustomerNameQuery
>;
export type FdrAccountCustomerNameLazyQueryHookResult = ReturnType<
  typeof useFdrAccountCustomerNameLazyQuery
>;
export type FdrAccountCustomerNameQueryResult = Apollo.QueryResult<
  FdrAccountCustomerNameQuery,
  FdrAccountCustomerNameQueryVariables
>;
