import React from 'react';
import { FdrLazyTooltip } from 'fdr/components/local/fdr-lazy-tooltip';
import { FdrSiteToolTooltipWrapper } from './fdr-site-tool-tooltip.styles';

type TProps = React.PropsWithChildren<{
  text: string;
  withAutoPositioning?: boolean;
}>;

export const FdrSiteToolTooltip: React.FC<TProps> = ({
  text,
  children,
  withAutoPositioning = true,
}) => {
  return (
    <FdrSiteToolTooltipWrapper className="fdr-tooltip-wrapper">
      {children}
      <FdrLazyTooltip withAutoPositioning={withAutoPositioning}>
        {text}
      </FdrLazyTooltip>
    </FdrSiteToolTooltipWrapper>
  );
};
