import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ReactNode, useMemo, useRef } from 'react';
import { SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH } from '@hotelplan/fdr.lib.ident.auth-config';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { useCurrentRoute } from '@hotelplan/fdr.lib.router.target-page';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { LOGIN_REDIRECT_PAGE_PARAM_NAME } from '@hotelplan/libs.login-redirect';
import {
  AUTH_ICON_DICT,
  PERSON_AUTH_ICON,
} from './fdr-account-header-button.constants';
import {
  FdrAccountHeaderBsButton,
  FdrAccountHeaderButtonIcon,
  FdrAccountHeaderButtonWrapper,
} from './fdr-account-header-button.styles';

interface IFdrAccountHeaderButtonProps {
  isAuthenticated: boolean;
  isMenuOpened: boolean;
  toggleMenu?(): void;
  closeMenu?(): void;
  accountHeaderMenu?: ReactNode;
}

export function FdrAccountHeaderButton({
  isAuthenticated,
  isMenuOpened,
  toggleMenu,
  closeMenu,
  accountHeaderMenu,
}: IFdrAccountHeaderButtonProps): React.ReactElement {
  const { push, asPath } = useRouter();
  const [, { language }] = useTranslation('myaccount');
  const { route: currentRoute } = useCurrentRoute<{}, EPageType>();

  const iconName = useMemo(
    () => (isAuthenticated ? PERSON_AUTH_ICON : AUTH_ICON_DICT[language]),
    [language, isAuthenticated]
  );

  const handleMyAccountButtonClick = () => {
    const query = new URLSearchParams({
      lang: language,
    });
    if (currentRoute && currentRoute.pageType !== EPageType.Home) {
      query.set(LOGIN_REDIRECT_PAGE_PARAM_NAME, asPath);
    }
    const loginPath = `${SSR_MY_ACCOUNT_LOGIN_ENDPOINT_PATH}?${query.toString()}`;
    isAuthenticated ? toggleMenu() : push(loginPath);
  };

  const myAccountHeaderButtonRef = useRef<HTMLButtonElement>(null);
  const myAccountHeaderMenuItemsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside<HTMLDivElement | HTMLButtonElement>(
    [myAccountHeaderMenuItemsRef, myAccountHeaderButtonRef],
    () => {
      if (!isMenuOpened) return;
      closeMenu();
    }
  );

  const authClassName = isAuthenticated ? 'authenticated' : '';
  const openedClassName = isMenuOpened ? 'menuOpened' : '';

  return (
    <FdrAccountHeaderButtonWrapper className={authClassName}>
      <FdrAccountHeaderBsButton
        className={`${authClassName} ${openedClassName}`}
        onClick={handleMyAccountButtonClick}
        ref={myAccountHeaderButtonRef}
      >
        <FdrAccountHeaderButtonIcon name={iconName} />
      </FdrAccountHeaderBsButton>

      <div ref={myAccountHeaderMenuItemsRef}>{accountHeaderMenu}</div>
    </FdrAccountHeaderButtonWrapper>
  );
}
