import styled from 'styled-components';
import { BsCircleButton } from '@hotelplan/core.fusion.bs-circle-button';

export const ActionsToggleButtonWrapper = styled(BsCircleButton).attrs({
  testId: 'handle-additional-meta-modules',
  icon: {
    name: 'close',
    size: `lg`,
  },
})((props: { active: boolean }) => ({
  transform: props.active ? 'none' : 'rotate(45deg)',
}));
