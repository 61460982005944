import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { forwardRef, useEffect, useRef } from 'react';
import { setTooltipPosition } from '@hotelplan/components.common.tooltip';
// @4export local import need to be removed
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import FavIcon from 'components/domain/fav-icon/FavIcon';
import {
  FavButton,
  FavButtonLink,
  WishlistIconLabelWrapper,
  WishlistProductWrapper,
} from 'fdr/components/candidate/fdr-wishlist-icon/fdr-wishlist-icon.styles';
import { IWishListIconProps } from 'fdr/components/candidate/fdr-wishlist-icon/fdr-wishlist-icon.types';
import { FdrLazyTooltip } from 'fdr/components/local/fdr-lazy-tooltip';

const ICON_WIDTH_MD = 28;
const ICON_HALF_WIDTH_MD = 10;

export const FdrWishlistIcon = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IWishListIconProps
>((props, ref) => {
  const [t] = useTranslation('wishlist');

  const {
    id,
    loading,
    wishlistProductCount,
    isActive = false,
    label,
    withLabel = !!props.label,
    withTooltip = true,
    activeTooltip = t('wishlist:icon.active.title'),
    inactiveTooltip = t('wishlist:icon.notActive.title'),
    counterCss,
    href,
    onClick = noop,
    className,
  } = props;

  const tooltipElRef = useRef<HTMLElement>(null);

  useEffect(
    function adjustTooltipPosition() {
      if (!tooltipElRef.current) return;

      setTooltipPosition(tooltipElRef.current as HTMLElement, {
        tooltipTargetObjectWidth: ICON_WIDTH_MD,
        tooltipTargetObjectHalfWidth: ICON_HALF_WIDTH_MD,
      });
    },
    [tooltipElRef]
  );

  const FavControl = (href ? FavButtonLink : FavButton) as unknown as React.FC<
    {
      className: string;
      onClick?(): void;
      href: string;
      children?: React.ReactNode;
      variant: E_BUTTON_TYPE;
    } & React.RefAttributes<HTMLButtonElement | HTMLAnchorElement>
  >;

  return (
    <FavControl
      data-id="fav-btn"
      variant={E_BUTTON_TYPE.ICON_BUTTON}
      className={`wishlist-icon ${className || ''} ${
        isActive ? 'active' : 'not-active'
      }`}
      onClick={onClick}
      href={href}
      ref={ref}
    >
      <FavIcon id={id} active={isActive} loading={loading} />
      {withTooltip && (
        <FdrLazyTooltip
          withAutoPositioning
          tooltipElRef={tooltipElRef}
          testId="wishlist-icon-tooltip"
        >
          {isActive ? activeTooltip : inactiveTooltip}
        </FdrLazyTooltip>
      )}
      {wishlistProductCount && wishlistProductCount > 0 && (
        <WishlistProductWrapper
          counterStyles={counterCss}
          className="counter"
          data-id="wishlist-product-count-id"
        >
          {wishlistProductCount}
        </WishlistProductWrapper>
      )}
      {withLabel && (
        <WishlistIconLabelWrapper
          className="label"
          data-id="wishlist-icon-label"
        >
          {label || t('wishlist:icon.label')}
        </WishlistIconLabelWrapper>
      )}
    </FavControl>
  );
});

if (process.env.NODE_ENV !== 'production') {
  FdrWishlistIcon.displayName = 'WishlistIcon';
}
