import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  FloatingButtonEventType,
  trackFloatingButton,
} from '@hotelplan/fdr.lib.track.meta';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { ActionsToggleButtonWrapper } from './fdr-actions-toggle-button.styles';

export const FdrActionsToggleButton: React.FC<{ children?: React.ReactNode }> =
  ({ children }) => {
    const [t] = useTranslation('common');
    const [isOptionsShowed, , , toggleOptions] = useToggleState(false);

    const onButtonClick = () => {
      trackFloatingButton(
        isOptionsShowed
          ? FloatingButtonEventType.CLOSE
          : FloatingButtonEventType.OPEN
      );
      toggleOptions();
    };

    return (
      <div>
        {isOptionsShowed ? children : null}
        <ActionsToggleButtonWrapper
          active={isOptionsShowed}
          onClick={onButtonClick}
        >
          {t('scrollToTop.button')}
        </ActionsToggleButtonWrapper>
      </div>
    );
  };
