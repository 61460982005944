import styled from 'styled-components';
import type { CSSObject } from 'styled-components';
import { BsLink } from '@hotelplan/core.basis.bs-link';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { IWishListIconProps } from 'fdr/components/candidate/fdr-wishlist-icon/fdr-wishlist-icon.types';

const labelStyles: CSSObject = {
  fontSize: '14px',
  fontWeight: 'normal',
};

export const WishlistProductWrapper = styled.span<{
  counterStyles?: CSSObject;
}>(
  ({ theme: { shadows } }) =>
    sx2CssThemeFn({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      backgroundColor: 'primary',
      color: 'white',
      borderRadius: '50%',
      fontSize: '12px',
      boxShadow: shadows.large,
      position: 'absolute',
      top: '2px',
      left: 28,
      lineHeight: '18px',
      transform: 'translate(-50%, -50%)',
    }),
  props => props.counterStyles
);

export const WishlistIconLabelWrapper = styled.span(labelStyles);

const favButtonStyles = sx2CssThemeFn({
  padding: '4px',
  fontSize: '0px',
  letterSpacing: 'initial',
  lineHeight: 1.3,
  svg: {
    margin: 0,
  },
  position: 'relative',
  cursor: 'pointer',
  ':hover': {
    '.tooltip, .tooltipArrow': {
      visibility: 'visible',
    },
    '.icon': {
      color: 'primary',
    },
  },
});

export const FavButton = styled(BsButtonWithIcon)(favButtonStyles);
export const FavButtonLink = styled(BsLink)(favButtonStyles);
