import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const HelpOverlayWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    bg: 'bgLightBlue',
    top: '101%',
    position: 'absolute',
    left: '0',
    zIndex: '10',
    boxShadow: 'cardShadow',
  })
);
