import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';

export const FdrAccountHeaderButtonWrapper = styled.div(
  ({ theme }) => theme.account.headerButton.wrapper
);

export const FdrAccountHeaderBsButton = styled(BsButton)(({ theme }) => ({
  '&&': { ...theme.account.headerButton.bsButton },
}));

export const FdrAccountHeaderButtonIcon = styled(BsIcon)(({ theme }) => ({
  '&&': { ...theme.account.headerButton.bsIcon },
}));
