import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const FdrAccountHeaderMenuWrapper = styled.div(
  ({ theme }) => theme.account.headerMenu.wrapper
);

export const FdrAccountHeaderMenuItems = styled.div(
  ({ theme }) => theme.account.headerMenu.items
);

export const FdrAccountHeaderMenuLogoutButton = styled(BsButtonWithIcon)(
  ({ theme }) => theme.buttons.linkBtn,
  ({ theme }) => theme.account.headerMenu.logoutButton
);
