import React from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { FdrAccountHeader } from './fdr-account-header';

export const FdrAccountSiteTool: React.FC = () => {
  const { channelType } = useAuthentication();
  const isAuthenticated = channelType !== AuthChannelType.B2CAnonymous;

  if (channelType === AuthChannelType.B2B) return null;

  return <FdrAccountHeader isAuthenticated={isAuthenticated} />;
};
