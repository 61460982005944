import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrWishlistIconQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FdrWishlistIconQuery = {
  __typename?: 'Query';
  fdrWishlistsProductCount: {
    __typename?: 'FdrWishlistsProductCountResponse';
    productCount?: number | null;
  };
  fdrWishlistPage?: {
    __typename?: 'FdrWishlistPage';
    webMeta: {
      __typename?: 'FdrWishlistWebMeta';
      listWebMeta?: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      } | null;
    };
  } | null;
};

export const FdrWishlistIconDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrWishlistIcon' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrWishlistsProductCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productCount' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrWishlistPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listWebMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrLink' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrWishlistIconQuery__
 *
 * To run a query within a React component, call `useFdrWishlistIconQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrWishlistIconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrWishlistIconQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdrWishlistIconQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrWishlistIconQuery,
    FdrWishlistIconQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrWishlistIconQuery,
    FdrWishlistIconQueryVariables
  >(FdrWishlistIconDocument, options);
}
export function useFdrWishlistIconLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrWishlistIconQuery,
    FdrWishlistIconQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrWishlistIconQuery,
    FdrWishlistIconQueryVariables
  >(FdrWishlistIconDocument, options);
}
export type FdrWishlistIconQueryHookResult = ReturnType<
  typeof useFdrWishlistIconQuery
>;
export type FdrWishlistIconLazyQueryHookResult = ReturnType<
  typeof useFdrWishlistIconLazyQuery
>;
export type FdrWishlistIconQueryResult = Apollo.QueryResult<
  FdrWishlistIconQuery,
  FdrWishlistIconQueryVariables
>;
