import dynamic from 'next/dynamic';
import React from 'react';

/***
 *  Way to avoid hydration errors
 ***/
export const FdrLazyTooltip = dynamic<{
  testId?: string;
  withAutoPositioning?: boolean;
  tooltipElRef?: React.MutableRefObject<HTMLSpanElement | null>;
  className?: string;
  children?: React.ReactNode;
}>(
  () =>
    import('@hotelplan/components.common.tooltip').then(
      module => module.Tooltip
    ),
  { ssr: false }
);
